let PubSub = require('pubsub-js');

const constants = {
  DOWNLOAD_RESOURCE: "DOWNLOAD_RESOURCE",
  IS_LOADING: "IS_LOADING",
  FILTERS_UPDATED: "FILTERS_UPDATED",
  NEW_TOAST_MESSAGE: "NEW_TOAST_MESSAGE",
  REFRESH_RESOURCES: "REFRESH_RESOURCES",
  UPDATE_DOMAIN_INDEX: "UPDATE_DOMAIN_INDEX",
  CLOSE_POPUP: "CLOSE_POPUP",
  LOCALIZATION_CHANGED: "LOCALIZATION_CHANGED"
}

let pub_function = function(event){
  return function(data){
    return PubSub.publish(event, data);
  }
}

let sub_function = function(event){
  return function(callback){
    return PubSub.subscribe(event, callback);
  }
}

let clear_all_function = ()=>{
  return PubSub.clearAllSubscriptions();
}
let publish = {
  download_resource: pub_function(constants.DOWNLOAD_RESOURCE),
  is_loading: pub_function(constants.IS_LOADING),
  filters_updated: pub_function(constants.FILTERS_UPDATED),
  new_toast_message: pub_function(constants.NEW_TOAST_MESSAGE),
  refresh_resources: pub_function(constants.REFRESH_RESOURCES),
  update_domain_index: pub_function(constants.UPDATE_DOMAIN_INDEX),
  close_popup: pub_function(constants.CLOSE_POPUP),
  localization_changed: pub_function(constants.LOCALIZATION_CHANGED)
}

let subscribe = {
  download_resource: sub_function(constants.DOWNLOAD_RESOURCE),
  is_loading: sub_function(constants.IS_LOADING),
  filters_updated: sub_function(constants.FILTERS_UPDATED),
  new_toast_message: sub_function(constants.NEW_TOAST_MESSAGE),
  refresh_resources: sub_function(constants.REFRESH_RESOURCES),
  update_domain_index: sub_function(constants.UPDATE_DOMAIN_INDEX),
  close_popup: sub_function(constants.CLOSE_POPUP),
  localization_changed: sub_function(constants.LOCALIZATION_CHANGED)
}

module.exports = {
  publish: publish,
  subscribe: subscribe,
  clear_all: clear_all_function
}
