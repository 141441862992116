import promiseHandler from '../tools/promiseHandler';
import mixpanel from 'mixpanel-browser';
import pub_sub from './pub_sub';
import defs from './defs';
import localization from './localized_strings';
        
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

function verifyUser(){
    return new Promise((resolve, reject)=>{
      let verificationRequest = {
            'method': 'GET',
            'url': '/v1/users'
        }
        promiseHandler.promiseRequest(verificationRequest).then((verificationResponse) => {
            if (verificationResponse.status === 200) {
                resolve(verificationResponse.data);
            }
            else{
              reject()
            }
        });
    })
}

function downloadResource(info){
        let resourceRequest = {
            'method': 'POST',
            'url': '/v1/resources/' + info.resourceUUID + '/download',
            'data': JSON.stringify({
                "size": info.size
            })
        }
  
        promiseHandler.promiseRequest(resourceRequest).then((resourceResponse) => {
            if (resourceResponse.status === 200) {
                downloadResourceAsPDF({
                    encodedResource: resourceResponse.data.encoded_resource,
                    title: info.resourceTitle,
                    size: info.size
                })
                mixpanel.track("downloaded_resource",{
                    "resource": info.resourceTitle,
                    "resource_uuid": info.resourceUUID
                })
                pub_sub.publish.close_popup()

                pub_sub.publish.new_toast_message({
                    type: 'success',
                    header: localization['toast.resource_downloaded_header'],
                    message: localization['toast.resource_downloaded_body'],
                })
            }
            else{
                pub_sub.publish.new_toast_message({
                    type: 'error',
                    header: localization['toast.gen_error_header'],
                    message: localization['toast.resource_download_error_body'],
                })
            }
        }).catch((error)=>{
            mixpanel.track("failed_downloaded_resource",{
                "resource": info.resourceTitle,
                "resource_uuid": info.resourceUUID,
                "error": error
            })

            pub_sub.publish.new_toast_message({
                type: 'error',
                 header: localization['toast.gen_error_header'],
                message: localization['toast.resource_download_error_body'],
            })
        });          
} 

function downloadResourceAsPDF(resource){
    const linkSource = "data:application/octet-stream;base64," + resource.encodedResource;
    const link = document.createElement('a');
    link.href = linkSource;
    link.target = "_blank";
    link.rel = "noreferrer";
    link.download = resource.title + " - " + resource.size + ".pdf";
    link.click()
}

function favoriteResource(info){
    return new Promise((resolve, reject)=>{
        let favoriteRequest = {
            'method': 'POST',
            'url': '/v1/resources/' + info.resourceUUID + '/favorite',
            'data': {
                favorited: info.saved
            }
        }

        promiseHandler.promiseRequest(favoriteRequest).then((favoriteResponse) => {
            if (favoriteResponse.status === 200) {
                
                if(!process.env.REACT_APP_TEST_ENVIRONMENT){
                    mixpanel.track("favorited_resource",{
                        "resource": info.resourceTitle,
                        "resource_uuid": info.resourceUUID,
                        "favorited": info.saved
                    })
                }

                if(info.saved){
                    pub_sub.publish.new_toast_message({
                        type: 'success',
                        header: localization['toast.resource_saved_header'],
                        message: localization['toast.resource_saved_body'],
                    })
                }

                resolve()
            }
        }).catch((error)=>{
            if(!process.env.REACT_APP_TEST_ENVIRONMENT){
                mixpanel.track("failed_favorited_resource",{
                    "resource": info.resourceTitle,
                    "resource_uuid": info.resourceUUID,
                    "favorited": info.favorited,
                    "error": error
                })
            }

             pub_sub.publish.new_toast_message({
                type: 'error',
                header: localization['toast.gen_error_header'],
                message: localization['toast.resource_save_error_header'],
            })
            reject()
        });          
    });
}

function favoriteCollection(info){
    return new Promise((resolve, reject)=>{
        let favoriteRequest = {
            'method': 'POST',
            'url': '/v1/collections/' + info.collectionUUID + '/favorite',
            'data': {
                favorited: info.favorited
            }
        }

        promiseHandler.promiseRequest(favoriteRequest).then((favoriteResponse) => {
            if (favoriteResponse.status === 200) {
                
                if(!process.env.REACT_APP_TEST_ENVIRONMENT){
                    mixpanel.track("favorited_collection",{
                        "collection": info.collectionTitle,
                        "collection_uuid": info.collectionUUID,
                        "favorited": info.favorited
                    })
                }

                pub_sub.publish.new_toast_message({
                    type: 'success',
                    header: info.favorited?localization['toast.collection_saved_header']:localization['toast.collection_unsaved_header'],
                    message:(info.favorited?localization['toast.collection_saved_body']:localization['toast.collection_unsaved_body']),
                })

                resolve()
            }
        }).catch((error)=>{
            if(!process.env.REACT_APP_TEST_ENVIRONMENT){
                mixpanel.track("failed_favorited_collection",{
                    "collection": info.collectionTitle,
                    "collection_uuid": info.collectionUUID,
                    "favorited": info.favorited,
                    "error": error
                })
            }

            pub_sub.publish.new_toast_message({
                type: 'error',
                header: localization['toast.gen_error_header'],
                message: localization['toast.collection_download_error_body'],
            })
            reject()
        });          
    });
}

function formatGrades(gradeLevels){
    let obj= gradeLevels.map((obj)=>{

        return (defs.grade_level[obj.trim()][0] + ' (' + localization['grade.' + obj.trim()] + ')')
    })
    return(obj.join(', '))
}

function isResourceNew(timestamp){
    const refDate = new Date(timestamp * 1000)
    const currentDate = new Date();
    let dateDifference = currentDate.getTime() - refDate.getTime();
    let daysDifference = Math.floor(dateDifference/1000/60/60/24)
    return(daysDifference < 13? true:false)
}

function removeCredentials(code){

    window.localStorage.removeItem('userData')
    document.cookie = "folio_token=; path=/; SameSite=Lax";
    document.cookie = "stytch_session_cookie=; path=/; SameSite=Lax";

    //logged out
    if(code === 200){
        mixpanel.track("logged_out",{
          location: window.location.pathname,
        }
      )
        window.location.replace("/?logged_out=true");
    }
    //session expired
    else if(code === 401){
        mixpanel.track("session_expired",{
          location: window.location.pathname
        }
      )
        window.location.replace("/login?session_expired=true")

    }
    //user not found
    else if(code === 404){
        window.location.replace("/")
    }
}

//Set the value of the cookie
function setTokenCookie(token){
      document.cookie = "folio_token=" + JSON.stringify({"token":token}) + "; path=/; SameSite=Lax";
  }
  
//Get the value of the cookie
function getTokenCookie(){
  return decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)folio_token\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
}

function sectionScrollHandler(e){
    if(e.target.id !=="collection_row"){
        if(e.target.scrollLeft === 0 ){
            document.getElementById("arrow_left_" + e.target.id).style.display = "none";
        }
        else if(e.target.scrollWidth - e.target.offsetWidth <= e.target.scrollLeft){
            document.getElementById("arrow_right_" + e.target.id).style.display = "none";
        }
        else{
            if(document.getElementById("arrow_left_" + e.target.id).style.display === "none" || document.getElementById("arrow_left_" + e.target.id).style.display===""){
                document.getElementById("arrow_left_" + e.target.id).style.display = "flex"
            }
            if(document.getElementById("arrow_right_" + e.target.id).style.display === "none"){
                document.getElementById("arrow_right_" + e.target.id).style.display = "flex"
            }
        }
    }
}

function manualScroll(props){
    let tempSection = document.getElementById(props.id);
    let gap = props.id = "ctas_row"? 96:32
    if(props.direction === "right"){
        tempSection.scroll({
            top: 0,
            left: tempSection.offsetWidth + tempSection.scrollLeft + gap,
            behavior: 'smooth'
        });
    }
    else if(props.direction === "left"){
        tempSection.scroll({
            top: 0,
            left: tempSection.scrollLeft - tempSection.offsetWidth - gap,
            behavior: 'smooth'
        });
    }
    else{
        let tempObj = tempSection.children[props.direction]
        tempObj.classList.add('active');
        tempSection.scroll({
            top: 0,
            left: tempObj.offsetLeft,
            behavior: 'smooth'
        });
    }
}

function replaceAccentCharacters(word){
    const accents=[["á","a"], ["é","e"], ["í","i"], ["ó","o"], ["ú","u"], ["ü","u"], ["ñ","n"]];
    for(let i = 0; i < accents.length; i++){
        word = word.replaceAll(accents[i][0],accents[i][1])
    }
    return word;
}


function titleToURL(title){
    let formattedTitle = title.replaceAll(' ', '_');
    formattedTitle = replaceAccentCharacters(formattedTitle);
    formattedTitle = formattedTitle.replaceAll('?','%3F')
    return(formattedTitle)
}

function sortByFolioStandard(a,b){
    return((a.category_index + "." + a.topic_index + "." + a.resource_index).localeCompare(b.category_index + "." + b.topic_index + "." + b.resource_index))
}

function linkSelected(pathname){

    mixpanel.track("resource_link_selected",{
        location: window.location.pathname,
        pathname: pathname
    })

    window.location.replace(pathname)
}

function changeLanguage(value, source){
    const userData = JSON.parse(window.localStorage.getItem('userData'))

    if(userData){
        let changeLanguageRequest = {
            'method': 'PUT',
            'url': '/v1/users',
            'data': {
                language: value
            }
        }
                            
        promiseHandler.promiseRequest(changeLanguageRequest).then((changeLanguageResponse) => {
            if (changeLanguageResponse.status === 201) {
                mixpanel.track("language_changed",{
                    source: source,
                    location: window.location.pathname,
                });
                window.localStorage.setItem('userData', JSON.stringify(changeLanguageResponse.data.user));
                window.location.reload();
            }
        })
    }
    else{
       // const defaultL = window.localStorage.getItem('de')
        localization.setLanguage(value)
        //Set the value of the cookie
        window.localStorage.setItem('defaultLanguage', value);
        window.location.reload()
       
    }
  }

let helperFunctions = {
  downloadResource, verifyUser, favoriteResource, favoriteCollection, removeCredentials, getTokenCookie, setTokenCookie, formatGrades, sectionScrollHandler, manualScroll, isResourceNew, replaceAccentCharacters, titleToURL, sortByFolioStandard, linkSelected, changeLanguage
}
export default helperFunctions