import React, { Component, Fragment } from 'react';
import '../css/resource_popup.scss'
import defs from '../tools/defs';
import pub_sub from '../tools/pub_sub'
import mixpanel from 'mixpanel-browser';
import { ReactComponent as CloseButton } from '../svg/close_icon.svg';

import LoadingImagePlaceholder from './LoadingImagePlaceholder';
import localization from '../tools/localized_strings';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

let quantityOptions = [];
for(let i =1; i< 30; i++){
    quantityOptions.push(<option className='c1' key={"option_" + i} value={i}>{localization.formatString(localization['gen.quantity'], i)}</option>)
}

class ResourcePopup extends Component {
    constructor(props){
        super(props)
        this.state={
            selectedSize: '',
            selectedResource: this.props.resources.findIndex(x=>x.language === this.props.selectedLanguage),
        }
        
        this.SizeOptions = this.SizeOptions.bind(this);
        this.LanguageOptions = this.LanguageOptions.bind(this);
    }

    render(){
        return (  
           <div className="popup-component" onClick={(e)=>{
                if(e.target.className === "popup-component"){
                    this.closePopup()
                }
           }}>
                <div id="popup_container" className={'popup-container ' + this.props.type} >
                    <button className="close-button" onClick={()=>{
                        this.closePopup()
                    }}>
                    <CloseButton/>
                    </button>
                    <h2 className='popup-header'>{this.props.type==='order'?localization['gen.order_print']:localization['gen.download']}</h2>
                    <div className='mobile-resource-title'>
                        <img src="https://cc-cdn.pss.co/assets/icons/small_placeholder_thumbnail.svg" alt='Resource is loading'/>
                        <p className='c1'>{this.props.resources[this.state.selectedResource].title}</p>
                    </div>
                    <div className='size-selection-container'>
                        <this.SizeOptions/>
                    </div>  
                    <div className='language-selection-container'>
                        <this.LanguageOptions/>
                    </div>
                    <div className='preview-container'>
                        <div className='preview-info'>
                            <div className='preview-image'>
                                <LoadingImagePlaceholder resource={this.props.resources[this.state.selectedResource]}/>
                            </div>
                            <div className='preview-options'>
                                <h5>{this.props.resources[this.state.selectedResource].title}</h5>
                                <ul>
                                    <li className='c1'>{localization['gen.size']}: <b>{this.state.selectedSize !== '' && localization['size.' + [this.state.selectedSize][0]]}</b></li>
                                    <li className='c1'>{localization['gen.language']}: <b>{(this.props.resources[this.state.selectedResource].language === "e"?localization['gen.en']:localization['gen.es'])}</b></li>
                                </ul>
                            </div>
                        </div>
                        {this.props.type==="order" &&
                            <div className="preview-order-container">
                                {this.state.selectedSize !== "" &&
                                    <div className='price-container'>
                                        <h4>{"$" + defs.price[this.state.selectedSize]}</h4>
                                        <p className='c2'>{localization['resource.popup_per']}</p>
                                    </div>
                                }
                            </div>
                        }
                        {this.props.type==="download" &&
                            <button className="download green"onClick={()=>{
                                pub_sub.publish.download_resource({
                                    resourceUUID: this.props.resources[this.state.selectedResource].uuid,
                                    resourceTitle: this.props.resources[this.state.selectedResource].title,
                                    size: this.state.selectedSize
                                })      
                            }}>
                                <h4>{localization['resource.download_pdf']}</h4>
                            </button>
                        }
                    </div>
                </div>
           </div>
        );
    }

    closePopup(){
        pub_sub.publish.close_popup();
    }

    componentDidMount(){ 
        const sizeButtons = document.getElementsByClassName("size-button");

        this.setState(()=>({
            selectedSize: sizeButtons[sizeButtons.length - 1].value
        }))
    }

    SizeOptions(){
        let sizeArray = this.props.baseResource.size.split(',');

        let sizeOptions = sizeArray.map((obj, index)=>{
            if(defs.price[obj] || (this.props.type === "download")){
                return(
                    <button className={"size-button " + (this.state.selectedSize === obj? 'selected':'')} key= {"resource_size_option_"  + index} value={obj} onClick={()=>{this.sizeSelected(obj)}}>
                        <img className='placeholder-img' alt="Loading your resource!" src={"https://cc-cdn.pss.co/assets/images/" + obj + "_placeholder.png"}/>
                        <div className='size-description'>
                            <h5>{localization['size.' + obj]}</h5>
                            <span className='c2'>{defs.size[obj][2]}</span>
                            <span className='p2'>{localization['size.description_' + obj]}</span>
                        </div>
                    </button>
                )
            }
            return("")
        })
        return(
            <Fragment>
                {sizeOptions}
            </Fragment>
        )
    }

    sizeSelected(value){
        this.setState(()=>({
            selectedSize: value
        }));
    }

    LanguageOptions(){
        const languageOrder = ['e','s','b'];
        let options = languageOrder.map((obj)=>{
            let index = this.props.resources.findIndex(x=>x.language === obj);
            if(index !== -1){
                return(
                    <button className={"language-button " + ((index === this.state.selectedResource)? 'selected':'')} key= {"resource_language_option_"  + index} onClick={()=>{this.languageSelected(index)}}>
                        <h5>{(obj === "e"? localization['gen.en']:localization['gen.es'])}</h5>
                    </button>
                )
            }
            return("")
        })
        return(
            <Fragment> 
                {options}
            </Fragment>
        )
    }

    languageSelected(value){
        this.setState(()=>({
            selectedResource: value
        }))
    }
}


export default ResourcePopup;