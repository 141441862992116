import React, { Component, Fragment } from 'react';
import helperFunctions from '../tools/helperFunctions';
import promiseHandler from '../tools/promiseHandler'; 
import localization from '../tools/localized_strings';
import mixpanel from 'mixpanel-browser';
import '../css/navigation_bar.scss';
import pub_sub from '../tools/pub_sub';
import { ReactComponent as CloseButton } from '../svg/close_icon.svg';
import { ReactComponent as SearchIcon } from '../svg/search_icon.svg';
import { ReactComponent as StarIcon } from '../svg/star_icon_small.svg';
import { ReactComponent as NoProfileIcon } from '../svg/no_profile_icon.svg';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug: process.env.REACT_APP_TEST_ENVIRONMENT}); 

const userData = JSON.parse(window.localStorage.getItem('userData'))

 //setup mixpanel link tracking 
mixpanel.track_links("#nav-logo", "folio_button_selected",{
  source: "navigation_bar",
  location: window.location.pathname,
  pathname:"/"
});
mixpanel.track_links("#nav-search","search_button_selected",{
  source: "navigation_bar",
  location: window.location.pathname,
  pathname:"/search"
});
mixpanel.track_links("#nav-home","navigation_link_selected",{
  source: "navigation_bar",
  location: window.location.pathname,
  pathname: '/'
});
mixpanel.track_links("#nav-library","navigation_link_selected",{
  source: "navigation_bar",
  location: window.location.pathname,
  pathname: '/library'
});
mixpanel.track_links("#nav-collections","navigation_link_selected",{
  source: "navigation_bar",
  location: window.location.pathname,
  pathname: '/collections'
});
mixpanel.track_links("#nav-saved","navigation_link_selected",{
  source: "navigation_bar",
  location: window.location.pathname,
  pathname: '/saved'
});
mixpanel.track_links("#dropdown-login","login_selected",{
  source: "navigation_dropdown",
  location: window.location.pathname,
  pathname: "/login"
});

class NavigationBar extends Component {

  constructor(props){
    super(props)
   
    this.state= {
      dropdownOpen: false,
      rewardsPoints: 0,
      defaultLanguage: (userData? userData.language:window.localStorage.getItem('defaultLanguage'))
    }
  }
   
  render(){   
    return (
        <div id='navigation_header' className="header">
          <div className="header-left">
            <a id="nav-logo" href='/' className="header-logo">
              <img  alt="folio logo" src="https://cc-cdn.pss.co/assets/brand/folio_logo.svg"/>
            </a>
            <a id="nav-search" href="/search">
              <SearchIcon className={window.location.pathname === "/search"? "header-search selected":"header-search"}></SearchIcon>
            </a>
          </div>
          <div className="header-links">
            <a id="nav-home" href="/" className={window.location.pathname === "/"? "selected":""}>{localization['nav.home']}</a>
            <a id="nav-library" href="/library" className={window.location.pathname === "/library"? "selected":""}>{localization['nav.library']}</a>
            <a id="nav-collections" href="/collections" className={window.location.pathname === "/collections"? "selected":""}>{localization['nav.collections']}</a>
            <a id="nav-saved" href="/saved" className={window.location.pathname === "/saved"? "selected":""}>{localization['nav.saved']}</a>
          </div> 
          <div className="header-actions">
            <button className='action-button referral' onClick={()=>{
              mixpanel.track("referrals_selected",{
                  source: "navigation_bar",
                  location: window.location.pathname,
                  badge_count: userData? userData.referral_credits: 0
                });
              window.location.replace('/referrals');
            }}>
              <span>{localization['nav.referrals']}</span>
              <img alt="Refer and earn rewards!" src="https://cc-cdn.pss.co/assets/gift.svg"/>
              {JSON.parse(window.localStorage.getItem('userData')) &&
              
                <div className='count-badge' style={{display: userData.referral_credits > 0? "flex":"none"}}>{userData.referral_credits}</div>
              }
            </button>
            {userData === null &&
              <Fragment>
                <button className="header-button green" onClick={()=>{
                  mixpanel.track("create_account_selected",{
                    source: "navigation_bar",
                    location: window.location.pathname
                  });
                  window.location.replace('/create-account')
                }}>
                  <StarIcon/>
                 {localization['nav.join_folio']}
                </button>
              </Fragment>
            }
              <button className="account-button" onClick={()=>{
                this.setState((state)=>({
                  dropdownOpen: !state.dropdownOpen
                }),()=>{
                  document.body.style.overflow = this.state.dropdownOpen?'hidden':'initial';
                });
              }}> 
                  <div className={"header-profile " + (userData?"": "no-profile ") + (this.state.dropdownOpen? "open":"")}>
                    {userData?
                      <span className="p2">{userData.first_name.charAt(0) + userData.last_name.charAt(0)}</span>
                      :<NoProfileIcon/>
                    }
                    <CloseButton className="header-close"/>
                  </div>        
                
                <div className={"header-menu" + (this.state.dropdownOpen?" open":"")}>
                  
                </div>
              </button>
          </div>

          <div className={"header-dropdown " + ((this.state.dropdownOpen)? "open":"")}>
              <button className='referral-button dropdown white' onClick={()=>{
                mixpanel.track("referrals_selected",{
                  source: "navigation_dropdown",
                  location: window.location.pathname,
                  badge_count: userData? userData.referral_credits: 0
                });
                this.dropdownItemHandler('/referrals')
              }}>
                {localization['nav.referrals']}
                <img alt="Refer and earn rewards!" src="https://cc-cdn.pss.co/assets/gift.svg"/>
                  {userData &&
                  <div className='count-badge' style={{display: userData.referral_credits > 0? "flex":"none"}}>{userData.referral_credits}</div>
                }            
                </button>
            
            
            {/* Pages */}
            <div className='header-links-mobile'>
              <button className="header-dropdown-item" onClick={()=>{this.dropdownItemHandler("/")}}>{localization['nav.home']}</button>
              <button className="header-dropdown-item" onClick={()=>{this.dropdownItemHandler("/library")}}>{localization['nav.library']}</button>
              <button className="header-dropdown-item" onClick={()=>{this.dropdownItemHandler("/collections")}}>{localization['nav.collections']}</button>
              <button className="header-dropdown-item" onClick={()=>{this.dropdownItemHandler("/saved")}}>{localization['nav.saved']}</button>
            </div>
            
            <button className="header-dropdown-item" onClick={()=>{
               mixpanel.track("contact_us_selected",{
                  source: "navigation_dropdown",
                  location: window.location.pathname,
                });
              this.dropdownItemHandler('/contact-us')
            }}>{localization['gen.contact_us']}</button>
            {userData &&
              !userData.school_subscription &&
                <Fragment>
                    <button className="header-dropdown-item" onClick={()=>{this.goToSubscriptionPortal()}}>{localization['gen.account']}</button>
                    <div className="dropdown-divider"/>
                </Fragment>
              }
            {userData?
              <button className="header-dropdown-item" onClick={()=>{this.signOut()}}>{localization['nav.log_out']}</button>
              :

              <div className='header-dropdown-button-row'>
                  <button className="header-dropdown-item login" onClick={()=>{
                    mixpanel.track("log_in_selected",{
                        source: "navigation_dropdown",
                        location: window.location.pathname,
                      });
                    this.dropdownItemHandler('/login')
                  }}>{localization['gen.log_in']}</button>
                
                  <div className='header-mobile-ctas'>
                    <button className="header-dropdown-button green" onClick={()=>{
                      window.location.replace('/create-account')
                    }}>
                      <StarIcon/>
                      {localization['nav.join_folio']}
                    </button>
                    <span className='c1'>{localization['nav.create_account'] + " " }
                      <a id="dropdown-login" href='/login'>{localization['gen.log_in']}</a>
                    </span>
                  </div>  
                </div>
            }
          </div>
        </div>
    );
  }

  signOut(){
    this.setState(()=>({
      dropdownOpen: false
    }),()=>{
       mixpanel.track("create_account_selected",{
          source: "navigation_dropdown",
          location: window.location.pathname
        });
      helperFunctions.removeCredentials(200);
    })
  }

  goToSubscriptionPortal(){
    let subscriptionPortalRequest = {
        'method': 'GET',
        'url': '/v1/payments/subscription-portal',
    }
    promiseHandler.promiseRequest(subscriptionPortalRequest).then((subscriptionPortalResponse) => {
        if (subscriptionPortalResponse.status === 200) {
          this.setState(()=>({
            dropdownOpen: false
          }),()=>{

             mixpanel.track("account_selected",{
                source: "navigation_bar",
                location: window.location.pathname
              });
            
            window.location = subscriptionPortalResponse.data.url
          });
        }
    });
  }

  dropdownItemHandler(location){

    mixpanel.track("navigation_link_selected",{
      source: "navigation_dropdown",
      location: window.location.pathname,
      pathname: localization
    });
    this.setState(()=>({
      dropdownOpen: false
    }),()=>{
      window.location = location
    });
  }
}

export default NavigationBar